@import "../glyphter-font/page-symbols.css";

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	src: local('Open Sans SemiBold'), local('OpenSans-SemiBold');
	src: url(../webfonts/OpenSans-SemiBold.eot); /* IE9 Compat Modes */
	src: url(../webfonts/OpenSans-SemiBold.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
	url(../webfonts/OpenSans-SemiBold.woff2) format('woff2'), /* Super Modern Browsers */
	url(../webfonts/OpenSans-SemiBold.woff) format('woff'), /* Pretty Modern Browsers */
	url(../webfonts/OpenSans-SemiBold.ttf) format('truetype'); /* Safari, Android, iOS */
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	src: local('Open Sans Bold'), local('OpenSans-Bold');
	src: url(../webfonts/OpenSans-Bold.eot); /* IE9 Compat Modes */
	src: url(../webfonts/OpenSans-Bold.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
	url(../webfonts/OpenSans-Bold.woff2) format('woff2'), /* Super Modern Browsers */
	url(../webfonts/OpenSans-Bold.woff) format('woff'), /* Pretty Modern Browsers */
	url(../webfonts/OpenSans-Bold.ttf) format('truetype'); /* Safari, Android, iOS */
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 800;
	src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold');
	src: url(../webfonts/OpenSans-ExtraBold.eot); /* IE9 Compat Modes */
	src: url(../webfonts/OpenSans-ExtraBold.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
	url(../webfonts/OpenSans-ExtraBold.woff2) format('woff2'), /* Super Modern Browsers */
	url(../webfonts/OpenSans-ExtraBold.woff) format('woff'), /* Pretty Modern Browsers */
	url(../webfonts/OpenSans-ExtraBold.ttf) format('truetype'); /* Safari, Android, iOS */
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Celtic Garamond Pro';
	src: url('../webfonts/CelticGaramondProBold.eot');
	src: url('../webfonts/CelticGaramondProBold.eot?#iefix') format('embedded-opentype'),
	url('../webfonts/CelticGaramondProBold.woff2') format('woff2'),
	url('../webfonts/CelticGaramondProBold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'Celtic Garamond 2nd';
	font-style: normal;
	//font-weight: 600;
	font-display: block;
	src: url(../webfonts/Celtic-Garamond-2nd.eot); /* IE9 Compat Modes */
	src: url(../webfonts/Celtic-Garamond-2nd.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
	url(../webfonts/Celtic-Garamond-2nd.woff2) format('woff2'), /* Super Modern Browsers */
	url(../webfonts/Celtic-Garamond-2nd.woff) format('woff'), /* Pretty Modern Browsers */
	url(../webfonts/Celtic-Garamond-2nd.ttf) format('truetype'); /* Safari, Android, iOS */
}

@font-face {
	font-family: 'Zenda';
	font-style: normal;
	//font-weight: 600;
	font-display: block;
	src: url(../webfonts/Zenda.eot); /* IE9 Compat Modes */
	src: url(../webfonts/Zenda.eot?#iefix) format('embedded-opentype'), /* IE6-IE8 */
	url(../webfonts/Zenda.woff2) format('woff2'), /* Super Modern Browsers */
	url(../webfonts/Zenda.woff) format('woff'), /* Pretty Modern Browsers */
	url(../webfonts/Zenda.ttf) format('truetype'); /* Safari, Android, iOS */
}

@font-face {
	font-family: 'Merienda One';
	src: url('../webfonts/MeriendaOne-Regular.eot');
	src: url('../webfonts/MeriendaOne-Regular.eot?#iefix') format('embedded-opentype'),
	url('../webfonts/MeriendaOne-Regular.woff2') format('woff2'),
	url('../webfonts/MeriendaOne-Regular.woff') format('woff'),
	url('../webfonts/MeriendaOne-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

@page-size-tiny: 380px;
@page-size-small: 450px;
@page-size-medium: 650px;
@page-size-max: 850px;
@body-font-size: 1.30rem;

*,
*::before,
*::after {
	box-sizing: border-box;
}

*:focus {
	outline: none;
}

html, body {
	width: 100%;
	height: 100%;
}

body {
	font-family: "Open Sans", sans-serif;
	background: url(/assets/images/empty_bg.gif) fixed;
	color: #fff;
	font-size: @body-font-size;
	font-weight: 600;
	line-height: 1.35;
	letter-spacing: -0.01rem;
}

img {
	border: none;
}

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	margin-bottom: 1rem;
	font-weight: 700;
	//line-height: 1.35;
}

h1, .h1 {
	font-size: 1.7em;
}

h1:not(.header) {
	font-variant-caps: small-caps;
	font-size: 1.8em;
}

h2, .h2 {
	font-size: 1.5em;
}

h3, .h3 {
	font-size: 1.3em;
}

h4, .h4 {
	font-size: 1.15em;
}

h5, .h5 {
	font-size: 1em;
}

h6, .h6 {
	font-size: 0.9em;
}

.fs-30 {
	font-size: 30%;
}

.fs-40 {
	font-size: 40%;
}

.fs-50 {
	font-size: 50%;
}

.fs-60 {
	font-size: 60%;
}

.fs-70 {
	font-size: 70%;
}

.fs-75 {
	font-size: 75%;
}

.fs-80 {
	font-size: 80%;
}

.fs-90 {
	font-size: 90%;
}

.fs-110 {
	font-size: 110%;
}

.fs-120 {
	font-size: 120%;
}

.fs-130 {
	font-size: 130%;
}

.fs-140 {
	font-size: 140%;
}

.fs-150 {
	font-size: 150%;
}

.fs-160 {
	font-size: 160%;
}

.fs-170 {
	font-size: 170%;
}

.fs-180 {
	font-size: 180%;
}

.fs-190 {
	font-size: 190%;
}

.fs-200 {
	font-size: 200%;
}

.op-90 {
	opacity: 0.9;
}

.op-80 {
	opacity: 0.8;
}

.op-70 {
	opacity: 0.7;
}

.op-60 {
	opacity: 0.6;
}

.op-50 {
	opacity: 0.5;
}

.op-40 {
	opacity: 0.4;
}

.op-30 {
	opacity: 0.3;
}

@media (max-width: @page-size-medium) {
	body {
		//font-size: @body-font-size * 0.95;
	}
	p {
		word-wrap: break-word;
		overflow-wrap: break-word;
		hyphens: auto;
	}
}


@media (max-width: @page-size-tiny) {
	body {
		//font-size: @body-font-size * 0.9;
	}
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
}

i.crusade, span.crusade, a .crusade {
	&::before {
		font-family: page-symbols;
		font-style: normal;
		font-weight: normal;
		line-height: 1;
		content: "†";
		position: relative;
		top: 5px;
		margin-left: -3px;
		margin-right: -3px;
	}
}

i.crusade, span.crusade {
	font-size: .95em;
}

a {
	color: #dd0049;
	text-decoration: none;
	background-color: transparent;

	.crusade {

		&::before {
			//position: absolute;
			font-size: 1.15em;
			//line-height: .9em;
			font-weight: bold;
			margin-left: 2px;
			line-height: 1px;
			top: 5px;
		}
	}
}

a:hover, a:active {
	color: #60d;
	text-decoration: none;
}

a:not([href]) {
	color: inherit;
	text-decoration: none;
}

a:not([href]):hover {
	color: inherit;
	text-decoration: none;
}

hr {
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
	height: 0;
	overflow: visible;
	margin-top: 7px;
	margin-bottom: 7px;
	border: 0;
	border-top: 1pt solid #f0d0a1;

	&.gray1 {
		border-color: #ccc;
	}
}

.darkRed1, .god-sub {
	color: #69030F;
}

.darkRed2, .god {
	color: #89030F;
}

.darkRed3, .blood {
	color: #a9030F;
}

.red1 {
	color: #be0313;
}

.red2 {
	color: #cd0314;
}

.red3 {
	color: #e30113;
}

.darkGold1, .jesus-sub {
	color: #63310f;
}

.darkGold2 {
	color: #79470F;
}

.darkGold3 {
	color: #8e580f;
}

.gold1 {
	color: #a26a0f;
}

.gold2 {
	color: #ae760f;
}

.gold3 {
	color: #db992c;
}

.darkOrange1 {
	color: #63110f;
}

.darkOrange2, .jesus {
	color: #79270F;
}

.darkOrange3 {
	color: #8e380f;
}

.orange1 {
	color: #a24a0f;
}

.orange2 {
	color: #ae560f;
}

.orange3 {
	color: #db692c;
}

.darkPurple1 {
	color: #480276;
}

.darkPurple2, .joseph, .st-joseph {
	color: #5c0290;
}

.darkPurple3 {
	color: #7402ac;
}

.purple1 {
	color: #8a09c9;
}

.purple2 {
	color: #a312ea;
}

.purple3 {
	color: #b623ff;
}

.darkBlue1, .michael-sub {
	color: #020270;
}

.darkBlue2 {
	color: #020290;
}

.darkBlue3 {
	color: #0202a0;
}

.blue1 {
	color: #0202b1;
}

.blue2 {
	color: #0101de;
}

.blue3 {
	color: #0000ff;
}

.darkGreen1 {
	color: #1e4002;
}

.darkGreen2, .pio, .padre-pio, .st-padre-pio, .saint, .bonaventure, .st-bonaventure {
	color: #235802;
}

.darkGreen3 {
	color: #287002;
}

.green1 {
	color: #2f8002;
}

.green2 {
	color: #339300;
}

.green3 {
	color: #3aaa00;
}

.darkPink1 {
	color: #710051;
}

.darkPink2, .mary {
	color: #920068;
}

.darkPink3, .rosa-mystica {
	color: #ae007d;
}

.pink1 {
	color: #b41f84;
}

.pink2 {
	color: #c243a1;
}

.pink3 {
	color: #db5fba;
}

.darkOcean1 {
	color: #023973;
}

.darkOcean2, .michael, .gabriel, .rafael, .angel, .st-michael, .st-gabriel, .st-rafael, .st-raphael, .st-angel {
	color: #024386;
}

.darkOcean3 {
	color: #02549e;
}

.ocean1 {
	color: #025fab;
}

.ocean2 {
	color: #0368ba;
}

.ocean3 {
	color: #0272cb;
}

.primary {
	color: #4f3282;
}

.black {
	color: #000;
}

.white {
	color: #fff;
}

@keyframes star1 {
	0% {
		transform: rotate(0);
		filter: none;
	}
	3% {
		filter: brightness(2.5) sepia(1) hue-rotate(7deg) saturate(7);
	}
	6% {
		transform: rotate(360deg);
		filter: none;
	}
	100% {
		transform: rotate(360deg);
		filter: none;
	}
}

@keyframes star2 {
	0% {
		transform: rotate(45deg);
		filter: none;
	}
	3% {
		filter: brightness(2.5) sepia(1) hue-rotate(7deg) saturate(7);
	}
	6% {
		transform: rotate(405deg);
		filter: none;
	}
	100% {
		transform: rotate(405deg);
		filter: none;
	}
}

@keyframes star3 {
	0% {
		transform: rotate(116deg);
		filter: none;
	}
	3% {
		filter: brightness(2.5) sepia(1) hue-rotate(7deg) saturate(7);
	}
	6% {
		transform: rotate(476deg);
		filter: none;
	}
	100% {
		transform: rotate(476deg);
		filter: none;
	}
}

.page {
	max-width: @page-size-max;
	min-height: 100%;
	margin: auto;
	background: #fff7f2;
	color: #000;
	padding: 50px 60px 50px 60px;
	text-shadow: 1px 1px 3px rgba(0, 0, 0, .2);
	position: relative;
	overflow-x: hidden;
	overflow-y: hidden;
	min-width: 320px;

	> .header {
		position: relative;
		//color: #472d74;
		//text-shadow: 2px 2px 3px #b49ae3;
		color: #3c1491;
		text-shadow: 1px 1px 3px #cdc8c1;
		margin-bottom: 20px;

		a {
			color: inherit;
		}

		.link {
			cursor: default;
		}

		a:hover, a:active, .link:hover, .link:active {
			color: #d06;
			text-decoration: none;
		}

		.background {
			font-family: "Celtic Garamond Pro", "Celtic Garamond 2nd", serif;
			font-weight: bold;
			//text-transform: uppercase;
			/*background: #f3b795;*/
			/* background: linear-gradient(43deg, #fed575 70%, #fff9f3 90%); */
			border-bottom: 3pt solid #4f3282;
			//border-top-right-radius: 80%;
			border-bottom-right-radius: 50%;
			position: absolute;
			bottom: 40px;
			left: 60px;
			padding: 15px 80px 0px 80px;
			min-height: 90px;
			/* box-shadow: 6px 3px 6px 0px rgba(0,0,0,.1); */

			h1 {
				transform: scale(1,1.05);
				line-height: 1.3;
			}
		}

		.headerTitle {
			font-family: "Merienda One", serif;
			font-weight: bold;
			//border-bottom-right-radius: 50%;
			position: absolute;
			top: 0;
			bottom: 35px;
			left: 130px;
			right: 80px;
			//padding: 0px 130px 0px 80px;
			//min-height: 140px;
			//box-shadow: 6px 3px 6px 0px rgba(0,0,0,.1);
			font-size: 1.4rem;
			line-height: 2.5rem;
			text-align: center;
			display: flex;
			justify-content: center;
			align-items: center;
			//border: 2px solid;
			border-bottom: 2px solid;
			border-bottom-right-radius: 35%;
			padding: 10px;
			border-left: none;
			border-bottom-left-radius: 3%;

			.frameBorder {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
			}

			.star {
				position: absolute;
			}

			.star1 {
				width: 21px;
				height: 21px;
				left: 0;
				bottom: 5px;
				animation-name: star1;
				animation-duration: 60s;
				animation-delay: 15s;
				animation-iteration-count: infinite;
			}

			.star2 {
				left: 30px;
				bottom: 20px;
				width: 19px;
				height: 19px;
				transform: rotate(45deg);
				opacity: .8;
				animation-name: star2;
				animation-duration: 60s;
				animation-delay: 16.5s;
				animation-iteration-count: infinite;
			}

			.star3 {
				left: 60px;
				bottom: 15px;
				width: 16px;
				height: 16px;
				transform: rotate(116deg);
				opacity: .6;
				animation-name: star3;
				animation-duration: 60s;
				animation-delay: 18s;
				animation-iteration-count: infinite;
			}

			a {
				z-index: 1;
			}

			h1 {
				font-weight: normal;
			}
		}

		.menuButton {
			margin: 5px -5px 0 auto;
			border: 1pt solid #8f66d8;
			align-self: flex-start;
			padding: 10px 7px 10px 10px;
			border-radius: 15%;
			font-weight: bold;
			text-transform: uppercase;
			z-index: 1;
			cursor: pointer;

			.button {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				height: 25px;
				margin-right: 4px;
				min-width: 33px;

				.bar {
					background: #3c1491;
					border-radius: 3px;
					height: 3pt;
					box-shadow: 2px 2px 3px #cdc8c1;
				}
			}

			.text {
				margin-top: 6px;
				font-size: 120%;
				display: none;
				margin-bottom: -5px;
			}

			&:hover, &:active {
				.button {
					.bar {
						background: #d06;
					}
				}
			}
		}

		.quickMenu {
			position: absolute;
			bottom: -20px;
			right: 0;
			letter-spacing: 0.25rem;
			text-transform: uppercase;
			z-index: 1;
			display: flex;
			justify-content: flex-end;
			align-items: center;
			flex-wrap: wrap;
			width: 77%;
			font-size: 1.2rem;
			font-weight: bold;
			min-height: 52px;

			.item {
				.subMenu {
					transition-property: max-height, visibility;
					transition-duration: 200ms;

					visibility: hidden;
					max-height: 0;
					overflow: hidden;
					position: absolute;
					background: #fffbf2;
					border: 1.5pt solid #4f3282;
					border-radius: 5px;
					letter-spacing: 0.1rem;
					color: #3c1491;
					text-shadow: 1px 1px 3px #cdc8c1;
					box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.2), inset 0 0 5px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1pt rgba(50,0,150,.2);
					margin-top: 3px;
					max-width: 100%;

					&.pullRight {
						right: -10px;
					}

					> div {
						padding: 9px 10px;

						&:not(:first-child) {
							border-top: 2px solid #786b95;
						}

						a {
							display: flex;
							align-items: baseline;
							word-break: break-word;

							i {
								margin-right: 7px;
							}

						}

						&.active {
							color: #ab0091;
						}

						.crusade {
							&::before {
								//line-height: 1.2em;
								margin-left: 0px;
							}
						}
					}
				}

				&:hover {
					.subMenu {
						transition-duration: 500ms;
						transition-delay: 100ms;

						visibility: visible;
						max-height: 1900px;
						z-index: 10;
					}
				}
			}
		}
	}
}

html[lang="de-DE"] .page .header .background {
	@media (min-width: @page-size-medium) {
		letter-spacing: 0.15em;
	}
	text-transform: uppercase;
}

html[lang="en-US"] .page .header .background {
	text-transform: uppercase;
}

html[lang="es-ES"] .page .header .background,
html[lang="fr-FR"] .page .header .background {
	font-variant: small-caps;
	letter-spacing: -0.05em;

	h1 {
		line-height: 1;
	}

	@media (min-width: @page-size-tiny) {
		padding-bottom: 3px;
	}

	@media (min-width: @page-size-small) {
		padding-bottom: 5px;
	}

	@media (min-width: @page-size-medium) {
		letter-spacing: -0.12em;
	}

	@media (max-width: @page-size-medium) {
		h1 {
			line-height: 0.95;
		}
	}

	@media (max-width: @page-size-tiny) {
		left: 30px;
		bottom: 40px;

		h1 {
			margin-bottom: 16px !important;
		}
	}
}

html[lang="zh-CN"] {

	h1.header {
		font-size: 2.5em;
	}

	.page {
		.header {
			.headerTitle {
				font-family: sans-serif;
				line-height: 3.9rem;
			}

			.background {
				h1.header {
					white-space: nowrap;
				}
			}

			@media (max-width: @page-size-tiny) {
				.background {
					bottom: 50px;
				}
			}
		}
	}
}


.celticGaramond {
	font-family: "Celtic Garamond Pro", "Celtic Garamond 2nd", serif;
	font-weight: bold;
}

.meriendaOne {
	font-family: "Merienda One", serif;
	font-weight: bold;
}

.pageIntro {
	padding: 15px 0;
	margin: 0 0 10px;
	color: #650ab0;
	border-top: 1pt solid #7636b7;
	text-align: center;
	//border-top-left-radius: 7%;
	//border-top-right-radius: 7%;

	.fas {
		//opacity: .9;
		font-size: .7em;
	}
}

@media (max-width: @page-size-medium) {
	.pageIntro {
		margin-top: 5px;
	}
}

.footNote {
	padding: 15px 0;
	margin: 30px 0 5px;
	color: #492995;
	border-top: 1pt solid #533897;
	text-align: center;
}

@media (min-width: @page-size-max) {
	body {
		padding-top: 40px;
		padding-bottom: 40px;
	}
	.page {
		box-shadow: inset 0 0 3px #000, inset 0 0 7px #000;
		border-radius: 30px;
		padding: 55px 70px 55px 70px;
	}
}

@media (max-width: @page-size-medium) {
	.page {
		padding: 50px 40px 40px 40px;
		word-wrap: break-word;

		> .header {
			margin-bottom: -7px;

			> * {
				transform: scale(0.85);
				transform-origin: top;
				font-size: 110%;
			}

			.logoShieldSword {
				transform-origin: top left;
			}

			.background {
				transform-origin: top left;
				left: 50px;
				bottom: 46px;
			}

			.pageLogo {
				transform-origin: top left;
			}

			.headerTitle {
				transform-origin: top left;
				transform: scale(.8);
				right: 0px;
				bottom: 30px;
				left: 110px;

				h1 {
					padding-bottom: 30px;
				}
			}

			.menuButton {
				margin-top: -5px;
				margin-right: 0px;
			}

			.quickMenu {
				transform-origin: bottom right;
				bottom: 3%;
				width: 87%;
				min-height: 41px;
				align-items: center;
				font-size: 1.3rem;
			}
		}
	}
}

@media (max-width: @page-size-small) {
	.page {
		padding: 40px 20px 30px 20px;

		> .header {
			padding-left: 5px;
			margin-bottom: -25px;

			> * {
				transform: scale(0.75);
			}

			.background {
				left: 50px;
			}

			.headerTitle {
				transform: scale(0.7);
				right: 0px;

				.star {
					bottom: auto;
				}

				.star1 {
					left: -30px;
					top: 0px;
				}

				.star2 {
					left: 0px;
					top: -16px;
				}

				.star3 {
					left: 30px;
					top: -15px;
				}

				h1 {
					padding-bottom: 0px;
				}
			}

			.menuButton {
				margin-top: -5px;
			}

			.quickMenu {
				bottom: 20px;
				width: 97%;
				min-height: 35px;
				font-size: 1.5rem;
			}
		}
	}
}

@media (max-width: @page-size-tiny) {
	.page {
		padding: 30px 20px 30px 20px;

		> .header {
			padding-left: 0px;
			margin-bottom: -40px;
			margin-top: -10px;

			> * {
				transform: scale(0.65);
			}

			.logoShieldSword {
				margin-top: 20px;
			}

			.background {
				left: 40px;
			}

			.headerTitle {
				transform: scale(0.6);
				top: 10px;
				bottom: 25px;
				left: 90px;
				right: -40px;
			}

			.menuButton {
				margin-top: 5px;
			}

			.pageLogo {
				margin-top: 20px;
			}

			.quickMenu {
				bottom: 20%;
				width: 110%;
				min-height: 30px;
				font-size: 1.6rem;
			}
		}
	}
}

/*
.shieldWithDoubleSword {
	background: url(/assets/vector/shield-1.svg) no-repeat;
	background-size: 100%;
	width: 125px;
	height: 157px;
	position: relative;
	z-index: 2;

	&::before {
		content: "";
		background: url(/assets/vector/double-sword-1.svg) no-repeat;
		background-size: 100%;
		width: 80px;
		height: 80px;
		position: absolute;
		top: 55px;
		left: 23px;
	}
}
*/

.logoShieldSword {
	position: relative;
	width: 120px;
	height: 155px;
	padding-bottom: 10px;
	z-index: 1;

	.shield {
		display: block;
		height: 100%;
	}

	.sword {
		display: block;
		position: relative;
		height: 73px;
		left: 21px;
		top: -88px;
	}
}

.pageLogo {
	position: relative;
	width: 112px;
	height: 195px;
	z-index: 1;

	.mariaCrown {
		display: block;
		height: 110%;
		filter: drop-shadow(2px 2px 3px #e2d1ff);
	}
}

.content {
	.link {
		font-size: 110%;
		line-height: 1.7;

		a.active {
			color: #D9230F;
		}
	}
}

/*
.space {
	padding-left: 5px;
	padding-right: 5px;
}
*/

.mainMenu {
	border: 2pt solid #4f3282;
	border-radius: 5px;
	box-shadow: 5px 5px 14px #ddd6d0;
	color: #472d74;
	text-shadow: 1px 1px 3px #cdcac1;
	transform-origin: top;
	//opacity: 0;
	max-height: 0;
	max-width: 1300px;
	margin-bottom: 0;
	padding: 0;
	//font-size: 0;
	//z-index: 1;
	overflow: hidden;
	visibility: hidden;
	margin-top: 10px;

	.medium {
		font-weight: 700;
	}

	.link {
		//margin-bottom: 4px;
		font-size: 105%;
		line-height: 1.15;
		width: 100%;
		padding: 9px 10px;

		.fas {
			width: 1.2em;
			text-align: center;
		}

		&:not(:last-child) {
			border-bottom: 1pt solid #4f3282;
		}

		&.main {
			background-color: #fde6d0;
		}

		&.sub {
			background-color: #fffae8;
			padding-left: 30px;
		}

		a {
			color: inherit;
		}

		span.noLink {
			cursor: default;
		}

		a, span.noLink {
			display: flex;
			align-items: baseline;

			i {
				margin-right: 7px;
			}
		}

		a {
			display: flex;
			align-items: baseline;

			&.active {
				color: #ab0091;
			}

			&:hover, &:active {
				color: #d06;
				text-decoration: none;
			}

			i {
				margin-right: 7px;
			}

			.crusade {
				&::before {
					//line-height: 1.2em;
					margin-left: -3px;
				}
			}
		}

		.subMenu {
			transition-property: max-height, visibility;
			transition-duration: 200ms;

			visibility: hidden;
			max-height: 0;
			overflow: hidden;
			position: absolute;
			//right: 0px;
			background-color: #fffae8;
			border: 2pt solid #4f3282;
			border-radius: 5px;
			//letter-spacing: 0.1rem;
			text-shadow: 1px 1px 3px #cdc8c1;
			box-shadow: 5px 5px 17px rgba(0, 0, 0, 0.2), inset 0 0 5px 1px rgba(0, 0, 0, 0.1);
			margin: 3px 10px 0 10px;
			font-weight: 600;
			z-index: 1;

			> div {
				padding: 9px 10px;

				&:not(:first-child) {
					border-top: 1pt solid #786b95;
				}

				a {
					display: flex;
					align-items: baseline;

					i {
						margin-right: 7px;
					}

				}

				&.active {
					color: #ab0091;
				}

				.crusade {
					&::before {
						//line-height: 1.2em;
						margin-left: 0px;
					}
				}
			}
		}

		&:hover {
			.subMenu {
				transition-duration: 500ms;
				transition-delay: 100ms;

				visibility: visible;
				max-height: 1500px;
				z-index: 10;
			}
		}
	}

	h3 {
		margin-bottom: 0;
	}

	&.open {
		visibility: visible;
		//opacity: 1;
		margin-bottom: 20px;
		//padding: 10px;
		max-height: 5000px;
		//max-width: 1300px;
		//font-size: 100%;
		transition-property: all;
		transition-duration: 500ms;
		transition-timing-function: ease-in;
	}

	&.close {
		visibility: hidden;
		//opacity: 0;
		max-height: 0;
		//max-width: 0;
		margin-bottom: 0;
		//padding: 0;
		//font-size: 0;
		transition-property: all;
		transition-duration: 500ms;
		transition-timing-function: ease-out;
	}

}

@media (max-width: @page-size-medium) {
	.mainMenu {
		.indent {
			padding-left: 25px;
		}
	}

	.link {
		&.sub {
			padding-left: 25px;
		}
	}
}

@media (max-width: @page-size-small) {
	.mainMenu {
		.indent {
			padding-left: 20px;
		}

		.link {
			&.sub {
				padding-left: 10px;
			}
		}
	}
}

.linkMenu {
	color: #472d74;
	text-shadow: 1px 1px 3px #d8ccee;

	//margin-bottom: 4px;
	font-size: 115%;
	//line-height: 1.7;

	.fas {
		width: 1.2em;
		text-align: center;
	}

	a {
		display: flex;
		align-items: baseline;
		justify-content: center;

		&:not(.active) {
			color: inherit;
		}

		&:hover, &:active {
			color: #d06;
			text-decoration: none;
		}
	}
}

@page {
	margin: 20mm 20mm 15mm 25mm;
}

@media print {
	.crusade {
		//display: none;
	}

	.linkMenu {
		display: none;
	}

	body {
		background: none;
	}

	.page {
		text-shadow: none;
		padding: 0 !important;
		background: none;

		> .header {
			margin-bottom: 10px;
		}
	}
}

.holyPicture {
	width: 190px;
	height: 268px;
	//width: 178px;
	//height: 250px;

	&.floatLeft {
		float: left;
		shape-outside: ellipse();
		//padding: 3px 13px 4px 0;
		margin: 3px 20px 4px 0px;
	}

	&.floatRight {
		float: right;
		shape-outside: ellipse();
		transform: scale(-1, 1);
		//padding: 3px 13px 4px 0;
		margin: 3px 0px 4px 20px;
	}
}

img.holyPicture {
	&:not(.noBorder) {
		border: 2.5pt solid #7f5102;
		padding: 1.5pt;
		background: #fffbf7;//#5e3e07
	}
	border-radius: 50%;
	box-shadow: 0px 0px 7px rgba(0,0,0,0.12);
}

@media (max-width: @page-size-medium) {
	.holyPicture {
		width: 174px;
		height: 245px;
	}
}

@media (max-width: @page-size-small) {
	.holyPicture {
		width: 158px;
		height: 222px;

		&.floatLeft {
			margin: 3px 13px 4px 0px;
		}

		&.floatRight {
			margin: 3px 0px 4px 13px;
		}
	}
}

@media print {
	.noPrint {
		display: none !important;
	}
	.hidePrint {
		visibility: hidden !important;
	}
}

@media screen {
	.noScreen {
		display: none !important;
	}
}

.battleFlag {
	max-height: 300px;
	max-width: 100%;
	padding-bottom: 1rem;
}

.battleFlagMary {
	//background: url(/assets/images/battle-flag-mary-800.jpg) no-repeat;
	//background-size: 100%;
	//width: 250px;
	max-height: 325px;
	max-width: 100%;
}

.battleFlagMichael {
	//background: url(/assets/images/battle-flag-michael-800.jpg) no-repeat;
	//background-size: 100%;
	//width: 265px;
	max-height: 325px;
	max-width: 100%;
}

.filterDarkGold {
	filter: brightness(0.6) sepia(1) hue-rotate(7deg) saturate(6);
}

.filterGold {
	filter: brightness(0.8) sepia(1) hue-rotate(7deg) saturate(6);
}

.filterDarkPurple {
	filter: brightness(0.5) sepia(1) hue-rotate(220deg) saturate(5);
}

.filterPurple {
	filter: brightness(0.6) sepia(1) hue-rotate(220deg) saturate(5);
}

.filterDarkRed {
	filter: brightness(0.5) sepia(1) hue-rotate(315deg) saturate(5);
}

.filterRed {
	filter: brightness(0.6) sepia(1) hue-rotate(315deg) saturate(5);
}

.filterDarkGreen {
	filter: brightness(0.5) sepia(1) hue-rotate(45deg) saturate(6);
}

.filterGreen {
	filter: brightness(0.7) sepia(1) hue-rotate(45deg) saturate(6);
}


.joiner-1 {
	width: 65%;
	height: 1%;
	margin: 25px auto 25px;
	display: block;
}

div.joiner-1:before {
	content: url(/assets/vector/joiner-1.svg);
}

@media (max-width: @page-size-medium) {
	.joiner-1 {
		width: 100%;
		margin: 15px auto 15px;
	}
}

.joiner-7 {
	width: 80%;
	height: 1%;
	margin: 5px auto 15px;
}

div.joiner-7:before {
	content: url(/assets/vector/joiner-7.svg);
}

.joiner-8 {
	width: 50%;
	height: 1%;
	margin: 20px auto 15px;
}

div.joiner-8:before {
	content: url(/assets/vector/joiner-8.svg);
}

.joiner-11 {
	width: 80%;
	height: 1%;
	margin: 20px auto 15px;
}

div.joiner-11:before {
	content: url(/assets/vector/joiner-11.svg);
}

@media (max-width: @page-size-medium) {
	.joiner-11 {
		width: 100%;
	}
}

.joiner-12 {
	width: 80%;
	height: 1%;
	margin: 30px auto 20px;
}

div.joiner-12:before {
	content: url(/assets/vector/joiner-12.svg);
}

@media (max-width: @page-size-medium) {
	.joiner-12 {
		width: 100%;
	}
}


.joiner-13-short {
	width: 6.6em;
	margin: 1.1em auto 0;
	//width: 127px;
	//margin: 25px auto 0;
	display: block;
}

div.joiner-13-short:before {
	content: url(/assets/vector/joiner-13-short.svg);
}

.joiner-14 {
	width: 9.9em;
	margin: 1.25em auto .75em;
	display: block;
}

div.joiner-14:before {
	content: url(/assets/vector/joiner-14.svg);
}


.splitter-1 {
	width: 100%;
	height: 15px;
	margin: 15px 0 25px;
	//margin: 1rem 0 1.5rem;
}

.splitter-6 {
	width: 100%;
	height: 35px;
	margin: 5px 0 15px;
}

.splitter-7 {
	width: 100%;
	height: 7px;
	margin: 5px 0 15px;
}

.splitter-10 {
	width: 100%;
}

.splitter-10-small {
	width: 100%;
}

.splitter-11 {
	width: 100%;
	height: 33px;
	margin: 15px 0 15px;
}

.splitter-13-short {
	width: 127px;
	margin: 25px auto 0;
	display: block;
}

div.splitter-13-short:before {
	content: url(/assets/vector/splitter-13-short.svg);
}

@media (max-width: @page-size-medium) {
	.splitter-1 {
		height: 13px;
		margin: 5px 0 15px;
	}
	.splitter-10 {
		display: none;
	}
	.splitter-11 {
		margin: 5px 0 5px;
	}
}

@media (min-width: @page-size-medium+1) {
	.splitter-10-small {
		display: none;
	}
}

.splitter-1-bg {
	width: 65%;
	height: 1%;
	//height: 15px;
	margin: 20px auto 20px;

	&:before {
		content: url(/assets/vector/splitter-1.svg);
	}
}

@media (max-width: @page-size-medium) {
	.splitter-1-bg {
		width: 100%;
	}
}

.splitter-10-bg {
	width: 100%;
	height: 1%;
}

@media (max-width: @page-size-medium) {
	.splitter-10-bg:before {
		content: url(/assets/vector/splitter-10-small.svg);
	}
}

@media (min-width: @page-size-medium+1) {
	.splitter-10-bg:before {
		content: url(/assets/vector/splitter-10.svg);
	}
}

.splitter-12-bg {
	width: 80%;
	height: 1%;
	margin: 30px auto 20px;

	&:before {
		content: url(/assets/vector/splitter-12-opt.svg);
	}
}

@media (max-width: @page-size-medium) {
	.splitter-12-bg {
		width: 100%;
	}
}

.splitter-12-short-bg {
	width: 25%;
	height: 1%;
	margin: 25px auto 15px;

	&:before {
		content: url(/assets/vector/splitter-12-short.svg);
	}
}

.sacred-heart-svg {
	width: 100%;
	height: 100%;

	&:before {
		content: url(/assets/vector/flame-of-love-heart-2.svg);
	}
}

.three-sacred-hearts-bg {
	width: 100%;
	height: 100%;

	&:before {
		content: url(/assets/vector/three-sacred-hearts.svg);
	}
}

.three-sacred-hearts-2 {
	//width: 100%;
	height: 100%;
	margin-bottom: -0.35em;
	width: 5em;

	&:before {
		content: url(/assets/vector/three-sacred-hearts-2.svg);
	}

	/*
	&.h2 {
		width: 7em;
	}

	&.h3 {
		width: 6em;
	}
	 */
}

.united-hearts-simple-rays-bg {
	width: 100%;
	height: 100%;

	&:before {
		content: url(/assets/vector/united-hearts-simple-rays-3-opt.svg);
	}

	&.holy-love {
		width: 3em;
		margin-bottom: -7px;

		&.sm {
			width: 2.5em;
			margin-bottom: -7px;
		}
	}
}

.rosa-mistica {
	width: 5em;
	height: 100%;
	margin: 0 auto -3px;

	&:before {
		content: url(/assets/vector/rosa-misitica.svg);
	}
}

.star1-bg {
	width: 100%;
	height: 100%;

	&:before {
		content: url(/assets/vector/star-1.svg);
	}
}

.pageCorner {
	width: 6rem;
	height: 6rem;
	position: absolute;

	&.top {
		top: 10px;
	}

	&.left {
		left: 10px;
	}

	&.bottom {
		bottom: 10px;
	}

	&.right {
		right: 10px;
	}
}

.rotate-90 {
	transform: rotate(90deg);
}

.rotate-180 {
	transform: rotate(180deg);
}

.rotate-270 {
	transform: rotate(270deg);
}

.flip-x {
	transform: scale(-1, 1);
}

.flip-y {
	transform: scale(1, -1);
}

.pageBorder {
	width: 100%;
	height: 30px;
	position: absolute;
	left: 0;

	&.top {
		top: 0px;
	}

	&.bottom {
		bottom: 0px;
	}
}

.footerTopArrow {
	position: absolute;
	bottom: 50px;
	left: 0;
}

@media (min-width: @page-size-max) {
	.pageBorder {
		&.top {
			top: 6px;
		}

		&.bottom {
			bottom: 6px;
		}
	}
}

@media (max-width: @page-size-medium) {
	.pageCorner {
		width: 5rem;
		height: 5rem;

		&.top {
			top: 6px;
		}

		&.left {
			left: 5px;
		}

		&.bottom {
			bottom: 6px;
		}

		&.right {
			right: 5px;
		}
	}

	.pageBorder {
		height: 22px;
	}

	.footerTopArrow {
		bottom: 37px;
	}
}

@media (max-width: @page-size-small) {
	.pageCorner {
		width: 4rem;
		height: 4rem;

		&.top {
			top: 5px;
		}

		&.left {
			left: 5px;
		}

		&.bottom {
			bottom: 5px;
		}

		&.right {
			right: 5px;
		}
	}

	.pageBorder {
		height: 16px;
	}

	.footerTopArrow {
		bottom: 30px;
	}
}

.alignCenter {
	text-align: center !important;
	align-items: center !important;
}

.alignRight {
	text-align: right !important;
	align-items: flex-end !important;
}

.alignLeft {
	text-align: left !important;
	align-items: flex-start !important;
}

.alignStretch {
	align-items: stretch !important;
}

.alignJustify {
	text-align: justify;
}

&.alignBaseline {
	align-items: baseline;
}

.justifyMiddle {
	justify-content: center !important;
}

.justifyTop {
	justify-content: flex-start !important;
}

.justifyBottom {
	justify-content: flex-end !important;
}

.justifySpaceBetween {
	justify-content: space-between !important;
}

.alignSelfCenter {
	align-self: center;
}

.text {
	text-align: justify;
}

div.pinBoard {
	border: 2px solid transparent;
	padding: 17px;
	border-radius: 7px;
	//background: rgba(255,200,200,.1);
	position: relative;
	box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.1);

	.close {
		position: absolute;
		top: 7px;
		font-size: 150%;
		//color: #ee4f4f;
		cursor: pointer;

		&.left {
			left: 12px;
			transform: rotate(-45deg);
		}

		&.right {
			right: 12px;
			transform: rotate(45deg);
		}
	}

	&.left-pin-removed {
		transform: rotate(-2deg);
		transform-origin: top right;

		.close.left {
			display: none;
		}
	}

	&.right-pin-removed {
		transform: rotate(2deg);
		transform-origin: top left;

		.close.right {
			display: none;
		}

		&.left-pin-removed {
			display: none;
		}
	}

	&.alert {
		background: rgba(255,200,200,.2);
		border-color: #c00;

		.close {
			color: #ee4f4f;
		}
	}

	&.warning {
		background: rgba(255,230,200,.2);
		border-color: #bb9500;

		.close {
			color: #d59c44;
		}
	}

	&.success {
		background: rgba(200,255,200,.2);
		border-color: #181;

		.close {
			color: #393;
		}
	}

	&.info {
		background: rgba(200,210,255,.2);
		border-color: #2d58ad;

		.close {
			color: #5f7be4;
		}
	}
}

.flex-1 {
	flex: 1;
}

.flex-2 {
	flex: 2;
}

.flex-3 {
	flex: 3;
}

.flex-4 {
	flex: 4;
}

.flex-5 {
	flex: 5;
}

.flex-6 {
	flex: 6;
}

.flex-7 {
	flex: 7;
}

.flex-8 {
	flex: 8;
}

.flex-9 {
	flex: 9;
}

.flex-10 {
	flex: 10;
}

.flex-11 {
	flex: 11;
}

.flex-12 {
	flex: 12;
}

.flexRow {
	display: flex;
	flex-direction: row;

	&:not(.autoWidth) {
		width: 100%;
	}
}

.flexColumn {
	display: flex;
	flex-direction: column;

	&:not(.autoWidth) {
		width: 100%;
	}
}

.flexWrap {
	flex-wrap: wrap;
}

@media (min-width: @page-size-tiny) {
	.noWrap {
		white-space: nowrap;
	}
}

.neverWrap {
	white-space: nowrap;
}

.disabled {
	opacity: .7;
	pointer-events: none;
}

.navigationIcon {
	width: 50px;
	height: 50px;
	cursor: pointer;
	border-radius: 50%;
}

@media (max-width: @page-size-medium) {
	.navigationIcon {
		width: 50px;
		height: 50px;
	}
}

@media (max-width: @page-size-small) {
	.navigationIcon {
		width: 40px;
		height: 40px;
	}
}

@keyframes swipe-content-fadeout-left {
	from {
		opacity: 1;
		transform: none;
	}
	to {
		opacity: 0;
		transform: translateX(-1000px);
	}
}

@keyframes swipe-content-fadein-left {
	from {
		opacity: 0;
		transform: translateX(1000px);
	}
	to {
		opacity: 1;
		transform: none;
	}
}

@keyframes swipe-content-fadeout-right {
	from {
		opacity: 1;
		transform: none;
	}
	to {
		opacity: 0;
		transform: translateX(1000px);
	}
}

@keyframes swipe-content-fadein-right {
	from {
		opacity: 0;
		transform: translateX(-1000px);
	}
	to {
		opacity: 1;
		transform: none;
	}
}

#swipe-content.fadeout-left {
	animation-name: swipe-content-fadeout-left;
	animation-duration: 700ms;
	animation-fill-mode: both;
}

#swipe-content.fadein-left {
	animation-name: swipe-content-fadein-left;
	animation-duration: 700ms;
	animation-fill-mode: both;
}

#swipe-content.fadeout-right {
	animation-name: swipe-content-fadeout-right;
	animation-duration: 700ms;
	animation-fill-mode: both;
}

#swipe-content.fadein-right {
	animation-name: swipe-content-fadein-right;
	animation-duration: 700ms;
	animation-fill-mode: both;
}

img.letter {
	max-width: 100%;
	border: 2px solid #905b14;
	border-radius: 2vw;
}

img.luz-de-maria {
	max-width: 100%;
	width: 396px;
	border: 3px solid #604828;
	border-radius: 33px;
}

img.anne {
	max-width: 100%;
	width: 250px;
	border: 4px solid #866b47;
	border-radius: 50px;
	float: right;
	margin-left: 15px;
	margin-bottom: 10px;
}

img.holy-family {
	max-width: 100%;
	width: 350px;
	border: 2pt solid #604828;
	border-radius: 17px;
	margin-bottom: 30px;
	margin-top: 15px;
}

img.seal-of-the-living-god {
	max-width: 100%;
	width: 200px;
}

img.medicinal-plant {
	width: 420px;
	max-width: 100%;
	border: 4px solid #630;
	border-radius: 12px;
	//box-shadow: 5px 5px 12px 0px rgba(0,0,0,0.2);
	box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.2);
	margin: 7px auto 2px;

	&.light {
		border-color: #824d18;
	}
}

img.picture, .video-container, video.video, .yt-video, .video-frame {
	max-width: 100%;
	width: 500px;
	height: auto;
	display: block;
	margin: 30px auto;
	//background: #fff;

	&:not(.noBorder) {
		border: 2pt solid #604828;
		border-radius: 33px;
		box-shadow: 0px 0px 7px rgba(0,0,0,0.12);

		&.light {
			border-color: #ac854e;
		}

		&.frame {
			border-style: solid;
			border-width: 7px;
			border-top-color: #85613e;
			border-right-color: #62472d;
			border-bottom-color: #85613e;
			border-left-color: #62472d;
			box-shadow: 0px 0px 7px rgba(0,0,0,0.3), inset 0px 0px 3px rgba(0,0,0,0.3);
			//border-radius: 9px;
			padding: 12px;
			&.picture {
				background: #fffdf8;
			}

			&.round {
				border-radius: 50%;
				border-color: #765537;
				/*
				border-top-color: #85613e;
				border-left-color: #85613e;
				border-right-color: #62472d;
				border-bottom-color: #62472d;

				 */
				&.light {
					border-color: #ac854e;
				}
			}

			&.w150, &.xs, &.xxs, &.w250 {
				padding: 7px;
				border-width: 5px;
				&:not(.round) {
					border-radius: 19px;
				}
			}

			@media (max-width: @page-size-small) {
				padding: 7px;
				border-width: 5px;
				&:not(.round) {
					border-radius: 19px;
				}
			}
		}
	}

	&.w150 {
		width: 150px;
	}

	&.xxs {
		width: 200px;
	}

	&.xs {
		width: 300px;
	}

	&.w250 {
		width: 250px;
	}

	&.w350 {
		width: 350px;
	}

	&.w450 {
		width: 450px;
	}

	&.w550 {
		width: 550px;
	}

	&.w650 {
		width: 650px;
	}

	&.small, &.sm {
		width: 400px;
	}

	&.medium, &.md {
		width: 500px;
	}

	&.big, &.large, &.lg {
		width: 600px;
	}

	&.xl {
		width: 720px;
	}

	&.auto-width {
		width: auto;
	}

	&.round {
		border-radius: 50%;
		border-width: 2.5pt;
	}

	&.bg-white {
		background: #fff;
	}

	&.flip-x {
		transform: scale(-1, 1);
	}

	/*
	&.page-bg {
		background: #fff7f2;
	}
	 */
}

.video-container {
	padding-top: 56.25%;
	height: 0px;
	position: relative;
}

video.video, .yt-video {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: black;
	margin: 0;
}

.emoji {
	//font-family: 'Source Sans Pro',Roboto,"San Francisco","Segoe UI",sans-serif;
	font-family: "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Segoe UI Symbol", "Android Emoji", EmojiSymbols, "Source Sans Pro", Roboto, "San Francisco", "Segoe UI",sans-serif;

	&.prayer {
		font-size: 80%;
		padding-left: 5px;
	}
}

.initial {
	&::first-letter {
		float: left;
		font-size: 3.9em;
		line-height: 1em;
		margin: -0.1em 0.1em -0.04em -0.05em;
		color: #79470f;
		text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
		font-family: Zenda, sans-serif;
		font-weight: normal;
	}
}

.initial3 {
	&::first-letter {
		float: left;
		font-size: 2.7em;
		line-height: 1em;
		margin: -0.05em 0.15em -0.04em -0.05em;
		color: #79470f;
		text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
		font-family: Zenda, sans-serif;
		font-weight: normal;
	}
}

p.initial2 {
	&::first-letter {
		float: left;
		font-size: 3.7em;
		line-height: .7em;
		margin: 0.05em 0.2em -0.1em -0.05em;
		color: #79470f;
		text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
	}
}

html[lang="zh-CN"] {
	.initial {
		&::first-letter {
			/*font-family: sans-serif;*/
		}
	}
}

label.toggle {
	display: flex;
	align-items: center;

	input {
		display: none;
	}

	.inactive {
		color: #999;
	}

	.label {
		padding-left: 15px;
	}

	&.checked {
		.inactive {
			display: none;
		}
	}

	&:not(.checked) {
		.active {
			display: none;
		}
	}
}

.search {
	input[type=text] {
		border-bottom-left-radius: 5px;
		border-top-left-radius: 5px;
		padding-left: 5px;
		padding-right: 5px;
		min-width: 50px;
	}

	input[type=submit] {
		min-height: 40px;
		border: 1px solid #777;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}

	.result {
		.header {
			background-color: rgb(255, 235, 217);
			border-top: 2px solid #82766b;
			color: #684b05;
			padding: 10px 7px;
			margin-bottom: 30px;
			margin-top: 10px;
		}

		.content {
			a {
				color: inherit;
			}
		}

		.footer {
			margin: 10px 0;
		}
	}
}


.dropdown {
	position: relative;
	display: inline-block;

	.dropdown-button {
		background-color: #FFDAB4;
		padding: 7px;
		cursor: pointer;
		border: 2px solid rgba(0,0,0,.2);
		border-radius: 5px;
	}

	.dropdown-content {
		display: none;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 150px;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		z-index: 1;
		max-height: 300px;
		overflow-y: auto;
		bottom: 30px;
		left: 5px;
		border: 2px solid #999;
		border-radius: 5px;

		a {
			color: black;
			padding: 12px 16px;
			text-decoration: none;
			display: block;

			&:hover {
				background-color: #f1f1f1;
			}

			&.active {
				background-color: #FFDAB4;

				&:hover {
					background-color: #eec492;
				}
			}
		}
	}

	&:hover {
		.dropdown-button {
			background-color: #eec492;
		}

		.dropdown-content {
			display: block;
		}
	}

}

.pagination {
	>div {
		margin-left: 5px;
	}

	.button {
		display: inline-block;
		padding: 7px;
		border: 2px solid rgba(0,0,0,.2);
		border-radius: 5px;
		min-width: 50px;
		text-align: center;
		height: 100%;
	}
}

#highlight-area {

	span.highlight {
		color: #df0000;
		background-color: #ffffd9;
		border: 1pt solid #999;
		border-radius: 6px;
	}
}

input[type=checkbox] {
	width: 25px;
	height: 25px;
	-webkit-appearance: none;
	-moz-appearance: none;
	border: 0.1mm solid black;
	position: relative;
	border-radius: 3px;
	box-shadow: 1px 1px 3px rgba(0,0,0,0.2);

	&:checked {
		background-color: #8B18CC;

		&:after {
			top: 0px;
			left: 7px;
			width: 10px;
			height: 18px;
			border: solid white;
			border-width: 0 1mm 1mm 0;
			-webkit-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
			content: "";
			//display: inline-block;
			position: absolute;
		}
	}

	&:disabled {
		background-color: #eee;
		border-color: #999;
		box-shadow: none;
	}
}

.hideInit, .hidden {
	display: none;
}

.clearfix {
	clear: both;
}

.yearsNav {
	padding: 0 30px;

	.year {
		padding-left: 15px;
		padding-right: 15px;
	}
}

@media (max-width: @page-size-medium) {
	.yearsNav {
		padding: 0 20px;

		.year {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
}

@media (max-width: @page-size-small) {
	.yearsNav {
		padding: 0 10px;

		.year {
			padding-left: 5px;
			padding-right: 5px;
		}
	}
}

@media (max-width: @page-size-tiny) {
	.yearsNav {
		padding: 0;
	}
}

.rosaryBlue {
	color: #466dcd;
	font-weight: bold;
}

.rosaryGreen {
	color: #429b35;
	font-weight: bold;
}

/*
.rosaryImage {
	width:560px;
	max-width:100%;
	margin-bottom: 10px;
}
*/

.listIndent {
	margin-left: 1.5rem;
}

@media (max-width: @page-size-medium) {
	.listIndent {
		margin-left: 1rem;
	}
}

@media (max-width: @page-size-small) {
	.listIndent {
		margin-left: 0.5rem;
	}
}

@media (max-width: @page-size-tiny) {
	.listIndent {
		margin-left: 0;
	}
}

.text.indent, .textIndent {
	text-indent: 20px;
}

.verseNumber {
	color: #666;
	font-size: .75em;
	font-weight: bold;
	position: relative;
	top: -3px;
}

.pbb-left {
	page-break-before: left;
}

.pbb-right {
	page-break-before: right;
}

.pbb-always {
	page-break-before: always;
}

.scale-y90 {
	transform: scale(1, 0.9);
}

.scale-y110 {
	transform: scale(1, 1.1);
}

.scale-y120 {
	transform: scale(1, 1.2);
}

.textBox {
	border-radius: 1em;
	border: 3pt double #804c00;
	padding: 1em;
	margin-bottom: 1rem;
}

.prayerBox {
	//border: 1.5pt solid #804c00;
	//border-bottom: none;
	//border-top: none;
	padding: 0 1em;
	line-height: 1.3;
	//background: rgba(255, 255, 255, 0.3);
	//margin-left: 1em;
	//margin-right: 1em;
	//box-shadow: inset 0pt 0pt 7pt rgba(0, 0, 0, 0.1);

	&.border {
		border-radius: 1em;
		border: 3pt double #804c00;
		padding: 1em;
		margin-bottom: 1rem;
	}
}

@media (max-width: @page-size-small) {
	.prayerBox {
		//margin-left: .5em;
		//margin-right: .5em;
	}
}

@media (max-width: @page-size-tiny) {
	.prayerBox {
		//margin-left: .25em;
		//margin-right: .25em;
	}
}

.prayer {
	line-height: 1.3;
}

.medium {
	font-weight: 700;
}

.bold {
	font-weight: 900;
}

.italic {
	font-style: italic;
}

.underline {
	text-decoration: underline;
}

.pageNavigation {
	//border-top: 2px solid;
	//border-bottom: 2px solid;
	//border-color: #c09656;
	//padding: 1em;
	//border-radius: 3em;
	//line-height: 1.3;
	//margin-left: 1em;
	//margin-right: 1em;
	margin-bottom: 1em;

	.ruler {
		width: 75%;
		margin: .2em auto;
		border-top: 1px solid #c09656;
	}

	.navItem {
		cursor: pointer;
		//border: 1px solid #c09656;
		border-radius: .3em;
		//margin-bottom: .2em;
		padding: 0.1em 0;
		color: #ab0091;
		font-weight: 700;

		.symbol:before {
			font-family: page-symbols;
			font-style: normal;
			font-weight: normal;
			line-height: 1;
			position: relative;
		}

		&:not(:hover):not(.active) {
			.symbol {
				&:before {
					content: "◌";
					font-size: .5em;
					top: -2px;
				}
			}
		}

		&:hover:not(.active) {
			background: rgba(255, 207, 188, 0.5);
			//color: #ab0091;
			//font-weight: bold;
			font-weight: 700;

			.symbol {
				&:before {
					content: "●";
					font-size: .5em;
					top: -2px;
				}
			}
		}

		&.active {
			//background: rgba(255, 228, 188, 0.5);
			background: rgba(255, 197, 212, 0.5);
			//background: rgba(255, 255, 210, 0.5);
			//color: #ab0091;
			//font-weight: bold;
			font-weight: 700;

			.symbol {
				&:before {
					content: "✦";
					font-size: .75em;
				}
				&.left:before {
					left: -2px;
				}
				&.right:before {
					left: 2px;
				}
			}
		}
	}
}

.tocHeader {
	//text-decoration: underline;
	border-bottom: 2px solid #5c029077;
	padding-bottom: 7px;
	//border-style: dashed;
	border-bottom-left-radius: 33%;
	border-bottom-right-radius: 33%;
	font-variant: small-caps;

	.symbol {
		position: relative;
		visibility: hidden;

		&.left {
			left: -1px;
		}
		&.right {
			left: 1px;
		}
	}
}

.tableOfContents {
	//border-color: #c09656;
	//border-radius: 3em;
	margin-bottom: 1em;

	.ruler {
		width: 75%;
		margin: .2em auto;
		border-top: 1px solid #c09656;
	}

	.tocItem {
		cursor: pointer;
		border-radius: .3em;
		padding: 0.1em 0;
		color: #ab0091;
		font-weight: 700;

		.symbol:before {
			font-family: page-symbols;
			font-style: normal;
			font-weight: normal;
			line-height: 1;
			position: relative;
		}

		&:not(:hover):not(.active) {
			.symbol {
				&:before {
					content: "◌";
					font-size: .5em;
					top: -2px;
				}
			}
		}

		&:hover:not(.active) {
			background: rgba(255, 207, 188, 0.5);
			//color: #ab0091;
			//font-weight: bold;

			.symbol {
				&:before {
					content: "●";
					font-size: .5em;
					top: -2px;
				}
			}
		}

		&.active {
			background: rgba(255, 197, 212, 0.5);
			//font-weight: 700;

			.symbol {
				&:before {
					content: "✦";
					font-size: .75em;
				}
				&.left:before {
					left: -2px;
					margin-right: -5px;
				}
				&.right:before {
					left: 2px;
					margin-left: -5px;
				}
			}
		}
	}
}

.numberBorder {
	border: 2pt solid;
	border-radius: 50%;
	padding: 2px 5px;
	min-width: 1.7em;
	text-align: center;
	display: inline-block;
	font-weight: bold;
}

ul.psm-list {
	list-style: none;
	margin-left: 0;
	padding-left: 0;

	li {
		padding-left: 2em;
		text-indent: -1.6em;

		>span, >b {
			color: black;
		}

		&:before {
			font-family: page-symbols;
			font-style: normal;
			font-weight: normal;
			line-height: 1;
			//content: "☙";
			font-size: .9em;
			padding-right: 1em;
		}
	}
}

/*
ul.psm-list.floral-heart1 li{
	padding-left: 1.4em;
	text-indent: -1.5em;

	&:before {
		content: "☙";
	}
}
*/

ul.psm-list.rose1 li {
	padding-left: 2.6em;
	text-indent: -1.8em;

	&:before {
		content: "🌹";
	}
}

ul.psm-list.heart1 li {
	&:before {
		content: "♥";
		font-size: .8em;
	}
}

ul.psm-list.heart1.indent2 li {
	text-indent: -1.9em;
}

ul.psm-list.star1 li {
	&:before {
		content: "★";
		font-size: .8em;
	}
}

ol {
	li {
		padding-left: 1em;
		text-indent: 0;
		margin-bottom: 5px;

		>span, >b {
			color: black;
		}
	}
}

.fa-xl {
	font-size: 1.5em;
}

.fa-2xs {
	font-size: 0.625em;
}

.starOpacity {
	opacity: .8;
}

.starOpacity2 {
	opacity: .7;
}

.fs-background {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
	background: rgba(0,0,0,0.67);

	img {
		max-width: 100%;
		max-height: 100%;
		border-radius: 5px;
		border: .15em solid #fff;
	}
}

.pointer {
	cursor: pointer;
}

@media (prefers-color-scheme: dark) {
	@import "dark";
}

.small-caps {
	font-variant-caps: small-caps;
}
